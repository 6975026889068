<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">帳號審核</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <!-- <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button> -->
          <div class="dropdown-box">
            <div
              class="dropdown-box__content box dark:bg-dark-1 p-2 w-32"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-10 mt-10">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <div class="w-32">審核狀態：</div>
            <select
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model="isAudited"
              @change="grid.refresh"
            >
              <option value="false">待審核</option>
              <option value="true">已審核</option>
            </select>
          </div>
          <!-- <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div> -->
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
            <!-- <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
               
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @edit="onGridEdit"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #operate="{ row }">
            <div class="flex justify-center" v-if="!row.isAudited">
              <button
                class="button rounded-full mr-1 bg-blue-600 text-white mr-2"
                title="審核"
                @click="grid.edit(row)"
              >
                <FontAwesome icon="highlighter" class="w-4 h-4" />
              </button>
            </div>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="
                $refs.form.validate(errMap => {
                  if (errMap === undefined) $refs.form.dispatchEvent('submit');
                })
              "
            ></vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <!-- <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              /> -->
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, {
  computed,
  defineComponent,
  reactive,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";

import formatDate from "xe-utils/toDateString";
export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const currentRow = ref<any>({});
    const modalVisible = ref(false);
    const isAudited = ref(false);
    const gridOptions: GridOptions = {
      id: "review",
      title: "帳號審核",
      multiselect: false,
      canCreate: true,
      canDelete: false,
      canUpdate: true,
      canRead: false,
      toolbarConfig: {
        custom: false,
        refresh: true
      },
      // printConfig: {
      //   sheetName: "最新快訊",
      //   columns: printColumns,
      //   modes: ["current", "selected", "all"]
      // },
      // exportConfig: {
      //   filename: "最新快訊",
      //   type: "csv",
      //   types: ["html", "csv"],
      //   mode: "all",
      //   modes: ["current", "selected", "all"],
      //   columns: printColumns
      // },
      columns: [
        {
          field: "createdTime",
          title: "申請時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          },
          formatter: ({ cellValue }) =>
            formatDate(cellValue, "yyyy/MM/dd HH:mm")
        },
        {
          field: "displayName",
          title: "顯示名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "name",
          title: "真實姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        // {
        //   field: "contact_Title",
        //   title: "職稱",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: false,
        //   editRender: {
        //     name: "$input",
        //     immediate: true,
        //     attrs: { type: "text" }
        //   }
        // },
        {
          field: "contact_Email",
          title: "業務信箱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Tel",
          title: "業務電話",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "isAudited",
          title: "審核狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          },
          formatter: ({ cellValue }) => (cellValue ? "已審核" : "待審核")
        },
        {
          field: "isAccepted",
          title: "審核結果",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          type: "html",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          },
          formatter: ({ cellValue }) => {
            if (cellValue === null || cellValue === undefined) return "";
            return cellValue
              ? "<font color='green'>通過</font>"
              : "<font color='red'>拒絕</font>";
          }
        },
        {
          field: "rejectReason",
          title: "備註",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          visible: isAudited.value,
          type: "html",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "operate",
          title: "審核",
          visible: !isAudited.value,
          align: "center",
          slots: { default: "operate" }
        }
      ],
      promises: {
        query: model
          ? params =>
              model.dispatch("vendor/queryAudits", {
                pageSize: params.pageSize,
                page: params.page,
                search: "null",
                isAudited: isAudited.value
              })
          : undefined,
        // query: () =>
        //   new Promise(resolve => {
        //     resolve(fakeData);
        //   }),
        queryAll: model
          ? () => model.dispatch("vendor/queryAudits")
          : undefined,
        update: model
          ? data => model.dispatch("vendor/updateAudits", data)
          : undefined
      },
      modalConfig: { showFooter: true, width: "500" }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        {
          field: "displayName",
          title: "顯示名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "",
              clearable: false,
              readonly: true
            },
            attrs: { type: "text" }
          }
        },
        {
          field: "name",
          title: "姓名",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "",
              clearable: false,
              readonly: true
            },
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Email",
          title: "業務信箱",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              clearable: false,
              readonly: true
            },
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Tel",
          title: "業務電話",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              clearable: false,
              readonly: true
            },
            attrs: { type: "text" }
          }
        },
        {
          field: "isAccepted",
          title: "審核結果",
          span: 24,
          itemRender: {
            name: "$select",
            props: {
              placeholder: "選擇審核結果",
              clearable: false,
              options: [
                { label: "通過", value: true },
                { label: "拒絕", value: false }
              ]
            }
          }
        },
        {
          field: "rejectReason",
          title: "不核准原因",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入不核准原因", clearable: true },
            attrs: { type: "text" }
          }
        }
      ],
      rules: {
        isAccepted: [{ required: true }]
      }
    };

    return {
      grid,
      gridOptions,
      isAudited,
      formOptions,
      currentRow,
      modalVisible
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    onGridEdit(row: any, callback: any) {
      callback();
    },
    submit(e: any) {
      console.log("🚀 ~ file: Main.vue ~ line 492 ~ onGridEdit ~ e", e);
    }
  }
});
</script>
