
import CloudFun, {
  computed,
  defineComponent,
  reactive,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";

import formatDate from "xe-utils/toDateString";
export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const currentRow = ref<any>({});
    const modalVisible = ref(false);
    const isAudited = ref(false);
    const gridOptions: GridOptions = {
      id: "review",
      title: "帳號審核",
      multiselect: false,
      canCreate: true,
      canDelete: false,
      canUpdate: true,
      canRead: false,
      toolbarConfig: {
        custom: false,
        refresh: true
      },
      // printConfig: {
      //   sheetName: "最新快訊",
      //   columns: printColumns,
      //   modes: ["current", "selected", "all"]
      // },
      // exportConfig: {
      //   filename: "最新快訊",
      //   type: "csv",
      //   types: ["html", "csv"],
      //   mode: "all",
      //   modes: ["current", "selected", "all"],
      //   columns: printColumns
      // },
      columns: [
        {
          field: "createdTime",
          title: "申請時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          },
          formatter: ({ cellValue }) =>
            formatDate(cellValue, "yyyy/MM/dd HH:mm")
        },
        {
          field: "displayName",
          title: "顯示名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "name",
          title: "真實姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        // {
        //   field: "contact_Title",
        //   title: "職稱",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: false,
        //   editRender: {
        //     name: "$input",
        //     immediate: true,
        //     attrs: { type: "text" }
        //   }
        // },
        {
          field: "contact_Email",
          title: "業務信箱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Tel",
          title: "業務電話",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "isAudited",
          title: "審核狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          },
          formatter: ({ cellValue }) => (cellValue ? "已審核" : "待審核")
        },
        {
          field: "isAccepted",
          title: "審核結果",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          type: "html",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          },
          formatter: ({ cellValue }) => {
            if (cellValue === null || cellValue === undefined) return "";
            return cellValue
              ? "<font color='green'>通過</font>"
              : "<font color='red'>拒絕</font>";
          }
        },
        {
          field: "rejectReason",
          title: "備註",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          visible: isAudited.value,
          type: "html",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "operate",
          title: "審核",
          visible: !isAudited.value,
          align: "center",
          slots: { default: "operate" }
        }
      ],
      promises: {
        query: model
          ? params =>
              model.dispatch("vendor/queryAudits", {
                pageSize: params.pageSize,
                page: params.page,
                search: "null",
                isAudited: isAudited.value
              })
          : undefined,
        // query: () =>
        //   new Promise(resolve => {
        //     resolve(fakeData);
        //   }),
        queryAll: model
          ? () => model.dispatch("vendor/queryAudits")
          : undefined,
        update: model
          ? data => model.dispatch("vendor/updateAudits", data)
          : undefined
      },
      modalConfig: { showFooter: true, width: "500" }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        {
          field: "displayName",
          title: "顯示名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "",
              clearable: false,
              readonly: true
            },
            attrs: { type: "text" }
          }
        },
        {
          field: "name",
          title: "姓名",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "",
              clearable: false,
              readonly: true
            },
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Email",
          title: "業務信箱",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              clearable: false,
              readonly: true
            },
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Tel",
          title: "業務電話",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              clearable: false,
              readonly: true
            },
            attrs: { type: "text" }
          }
        },
        {
          field: "isAccepted",
          title: "審核結果",
          span: 24,
          itemRender: {
            name: "$select",
            props: {
              placeholder: "選擇審核結果",
              clearable: false,
              options: [
                { label: "通過", value: true },
                { label: "拒絕", value: false }
              ]
            }
          }
        },
        {
          field: "rejectReason",
          title: "不核准原因",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入不核准原因", clearable: true },
            attrs: { type: "text" }
          }
        }
      ],
      rules: {
        isAccepted: [{ required: true }]
      }
    };

    return {
      grid,
      gridOptions,
      isAudited,
      formOptions,
      currentRow,
      modalVisible
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    onGridEdit(row: any, callback: any) {
      callback();
    },
    submit(e: any) {
      console.log("🚀 ~ file: Main.vue ~ line 492 ~ onGridEdit ~ e", e);
    }
  }
});
